import { HttpClient } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router, RouterModule } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { EnvService, Ic2Module, MsPipe } from '@ic2/ic2-lib';
import { NgbDatepickerI18n, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MissingTranslationHandler, TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import * as Sentry from '@sentry/angular';
import { OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import {
  ClientEnv,
  Config,
  HijiCommonModule,
  HijiHttpTranslateLoader,
  InterpolatedMissingTranslationHandler,
  LocalizedDatePipe,
  NgbDatepickerI18nCustom,
  loadClientStyle,
} from 'common';
import { environment } from 'environments/environment';
import { BaseChartDirective, provideCharts, withDefaultRegisterables } from 'ng2-charts';
import { CookieModule } from 'ngx-cookie';
import { MomentModule } from 'ngx-moment';
import { firstValueFrom } from 'rxjs';
import { trustedResourceUrl, unwrapResourceUrl } from 'safevalues';
import { AppComponent } from './app.component';
import { ObservableGestureModule } from './components/observable-gesture.module';
import { FrontLayoutComponent } from './front-layout/front-layout.component';
import { AskOpinionComponent } from './pages/ask-opinion/ask-opinion.component';
import { ContactComponent } from './pages/contact/contact.component';
import { FeedbackMessageModule } from './pages/fbs/feedback-message/feedback-message.module';
import { GroupActionsComponent } from './pages/group-actions/group-actions.component';
import { GroupEditComponent } from './pages/group-edit/group-edit.component';
import { GroupCardComponent } from './pages/group-list/group-card/group-card.component';
import { GroupListComponent } from './pages/group-list/group-list.component';
import { GroupSearchUserComponent } from './pages/group-search-user/group-search-user.component';
import { HomeModule } from './pages/home/home.module';
import { UserCardModule } from './pages/home/user-card/user-card.module';
import { KioskLoginComponent } from './pages/kiosk-login/kiosk-login.component';
import { MyProfileComponent } from './pages/my-profile/my-profile.component';
import { SearchUserComponent } from './pages/search-user/search-user.component';
import { StaticPageComponent } from './pages/static-page/static-page.component';
import { UserActionsCampaign180Component } from './pages/user-actions/user-actions-campaign180/user-actions-campaign180.component';
import { UserActionsEmaComponent } from './pages/user-actions/user-actions-ema/user-actions-ema.component';
import { KpiStatsComponent } from './pages/user-actions/user-actions-kpi/kpi-stats/kpi-stats.component';
import { UserActionsKpiComponent } from './pages/user-actions/user-actions-kpi/user-actions-kpi.component';
import { UserActionsPdiComponent } from './pages/user-actions/user-actions-pdi/user-actions-pdi.component';
import { UserActionsResetPwdPincodeComponent } from './pages/user-actions/user-actions-reset-pwd-pincode/user-actions-reset-pwd-pincode.component';
import { UserActionsTeamComponent } from './pages/user-actions/user-actions-team/user-actions-team.component';
import { UserActionsTeamboardComponent } from './pages/user-actions/user-actions-teamboard/user-actions-teamboard.component';
import { UserActionsComponent } from './pages/user-actions/user-actions.component';
import { VisualizeBilanCardComponent } from './pages/visualize/visualize-bilan-card/visualize-bilan-card.component';
import { VisualizeBilanComponent } from './pages/visualize/visualize-bilan/visualize-bilan.component';
import { VisualizeCampaign360CardComponent } from './pages/visualize/visualize-campaign360-card/visualize-campaign360-card.component';
import { VisualizeExchangeCardComponent } from './pages/visualize/visualize-exchange-card/visualize-exchange-card.component';
import { VisualizeSollicitation360CardComponent } from './pages/visualize/visualize-sollicitation360-card/visualize-sollicitation360-card.component';
import { VisualizeSurveyCardComponent } from './pages/visualize/visualize-survey-card/visualize-survey-card.component';
import { VisualizeSurveyComponent } from './pages/visualize/visualize-survey/visualize-survey.component';
import { VisualizeComponent } from './pages/visualize/visualize.component';
import { VisualizeModule } from './pages/visualize/visualize.module';
import { routes } from './routes';
import { TestComponent } from './test/test.component';
import { TranslateTextsComponent } from './translate-texts/translate-texts.component';

// AoT requires an exported function for factories
export function createTranslateLoader(http: HttpClient) {
  return new HijiHttpTranslateLoader(http);
}
export class DynamicLocaleId extends String {
  constructor(protected service: TranslateService) {
    super('');
  }

  toString() {
    return this.service.currentLang;
  }
}

@NgModule({
  declarations: [
    AskOpinionComponent,
    AppComponent,
    FrontLayoutComponent,
    StaticPageComponent,
    MyProfileComponent,
    ContactComponent,
    TestComponent,
    TranslateTextsComponent,
    UserActionsComponent,
    GroupActionsComponent,
    GroupEditComponent,
    GroupListComponent,
    SearchUserComponent,
    VisualizeComponent,
    GroupCardComponent,
    GroupSearchUserComponent,
    VisualizeBilanCardComponent,
    VisualizeCampaign360CardComponent,
    VisualizeExchangeCardComponent,
    VisualizeSurveyCardComponent,
    VisualizeSurveyComponent,
    VisualizeBilanComponent,
    VisualizeSollicitation360CardComponent,
    KioskLoginComponent,
    UserActionsEmaComponent,
    KpiStatsComponent,
    UserActionsKpiComponent,
    UserActionsCampaign180Component,
    UserActionsPdiComponent,
    UserActionsTeamComponent,
    UserActionsTeamboardComponent,
    UserActionsResetPwdPincodeComponent,
  ],
  imports: [
    ObservableGestureModule, //TODO supprimer ca des que on aura tout refactorise en modules
    HomeModule,
    UserCardModule, //TODO to remove in the future when we moved user-actions/visualize in a module
    FeedbackMessageModule, //TODO to remove in the future when we moved visualize in a module
    BrowserModule,
    ReactiveFormsModule,
    FormsModule,
    Ic2Module,
    HijiCommonModule,
    BaseChartDirective,
    VisualizeModule,
    MomentModule.forRoot({
      relativeTimeThresholdOptions: {
        m: 59,
      },
    }),
    CookieModule.forRoot(),
    OAuthModule.forRoot(),
    ServiceWorkerModule.register(unwrapResourceUrl(trustedResourceUrl`ngsw-worker.js`) as string, {
      enabled: environment.production,
      // Register the ServiceWorker as soon as the app is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    BrowserAnimationsModule,
    NgbModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
      missingTranslationHandler: { provide: MissingTranslationHandler, useClass: InterpolatedMissingTranslationHandler },
      defaultLanguage: 'en',
      extend: true,
    }),
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' }),
  ],
  providers: [
    //provideExperimentalZonelessChangeDetection(),
    //provideZoneChangeDetection({ eventCoalescing: true }),
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    { provide: OAuthStorage, useValue: localStorage },

    { provide: LOCALE_ID, useClass: DynamicLocaleId, deps: [TranslateService] },
    { provide: NgbDatepickerI18n, useClass: NgbDatepickerI18nCustom },
    MsPipe,
    LocalizedDatePipe,
    {
      provide: APP_INITIALIZER,
      useFactory: (envService: EnvService<Config>, clientEnv: ClientEnv) => {
        console.log('loading env for', window.location.hostname);

        if (['hiji.local', 'localhost', '192.168.1.1', 'maison.deepneel.fr'].includes(window.location.hostname)) {
          envService.confUrl = window.location.protocol + '//' + window.location.hostname + ':8080/front.config.json';
        } else {
          envService.confUrl = window.location.protocol + '//api.' + window.location.hostname + '/front.config.json';
        }

        return () =>
          new Promise<void>((resolve, reject) => {
            let timeout = setTimeout(() => {
              reject(new Error('Time'));
            }, 5000);

            firstValueFrom(envService.load(new Config()))
              .then((config) => {
                loadClientStyle(config, clientEnv);
                clearTimeout(timeout);
                resolve();
              })
              .catch((e) => {
                console.error(e);
                clearTimeout(timeout);
                reject(new Error('Down'));
              });
          });
      },
      deps: [EnvService, ClientEnv],
      multi: true,
    },
    provideCharts(withDefaultRegisterables()),
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
